export function setArtDirection(desktopImage, mobileImage) {
  if (!desktopImage?.fluid && !mobileImage?.fluid) {
    return {}
  }

  desktopImage = desktopImage || mobileImage
  mobileImage = mobileImage || desktopImage

  return {
    artDirection: [
      {
        ...desktopImage.fluid,
        description: desktopImage?.description || mobileImage?.description,
        media: '(min-width: 1024px)',
        sizes: '(max-width: 1920px)',
      },
      {
        ...mobileImage.fluid,
        description: mobileImage?.description || desktopImage?.description,
        sizes: '(max-width: 1024px)',
      },
    ],
  }
}
