import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { ButtonCta } from 'components/ButtonCta'
import { Container as CreditasContainer } from 'components/Container'
import { Grid } from 'components/Grid'
import { LazyImage } from 'components/LazyImage'
import ReclameAquiAward from 'components/ReclameAquiAward'

import { GridItem } from '../base.styles'
import { Description as StyledDescription } from '../components/Description'

const Wrapper = styled(CreditasContainer)`
  margin-top: ${selectTheme('spacingBottom.8xl')};
  min-height: auto;

  ${media.down('4xl')} {
    padding-left: 0;
    padding-right: 0;
  }

  ${media.up('5xl')} {
    margin-top: 0;
    min-height: 662px;
  }
`

const Container = styled(Grid)`
  max-height: 100%;
  max-width: 100%;

  grid-template-rows: max-content;
  grid-template-areas:
    '${'content '.repeat(4)}'
    '${'subtitle '.repeat(4)}'
    '${'content-footer '.repeat(4)}'
    '${'image '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'content '.repeat(8)}'
      '${'subtitle '.repeat(8)}'
      '${'content-footer '.repeat(8)}'
      '${'image '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-areas:
      '${'content '.repeat(6)} . ${'image '.repeat(5)}'
      '${'subtitle '.repeat(5)} . . ${'image '.repeat(5)}'
      '${'content-footer '.repeat(5)} . . ${'image '.repeat(5)}';
  }
`

const ContentTitle = styled(GridItem)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 ${selectTheme('spacingLeft.5xl')};
  width: 100%;

  ${media.up('5xl')} {
    padding-left: 0;
    padding-right: 0;
    padding-top: ${selectTheme('spacingTop.14xl')};
  }
`

const ContentSubTitle = styled(GridItem)`
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  ${media.up('5xl')} {
    padding-left: 0;
    padding-right: 0;
  }
`

const ContentFooter = styled(GridItem)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-left: ${selectTheme('spacingLeft.5xl')};
  padding-right: ${selectTheme('spacingRight.5xl')};
  width: 100%;

  ${media.up('5xl')} {
    padding-bottom: ${selectTheme('spacingBottom.14xl')};
    padding-left: 0;
    padding-right: 0;
  }
`

const Description = styled(StyledDescription)`
  margin-top: ${selectTheme('spacingTop.6xl')};
  margin-bottom: ${selectTheme('spacingBottom.10xl')};
`

const Actions = styled.div``

const ExtraInfo = styled.div`
  margin-top: ${selectTheme('spacingTop.16xl')};
`

const StyledReclameAquiAward = styled(ReclameAquiAward)`
  color: ${selectTheme('colors.neutral.80')};
  margin-bottom: ${selectTheme('spacingBottom.10xl')};
  width: 100%;

  p {
    color: ${selectTheme('colors.neutral.80')};
  }

  ${media.up('5xl')} {
    margin-bottom: 0;
  }
`

const imageStyle = css`
  height: auto !important;

  ${media.up('5xl')} {
    height: 100% !important;
  }
`

const Image = styled(LazyImage)`
  ${media.up('5xl')} {
    height: auto;
    top: 0;
  }
`

const ImageContainer = styled(GridItem)`
  position: relative;
  height: 100%;
`

const StyledSupportCardWrapper = styled.div`
  bottom: -84px;
  left: 0;
  margin: 0 auto;
  max-width: 80%;
  position: absolute;
  right: 0;

  ${media.up('sm')} {
    bottom: -64px;
  }

  ${media.up('5xl')} {
    bottom: 83px;
    left: -104px;
    margin: 0;
    right: auto;
    width: 287px;
  }
`

const StyledButtonCta = styled(ButtonCta)`
  height: 44px;
  margin-bottom: ${selectTheme('spacingBottom.8xl')};
  min-width: 187px;
  padding: ${selectTheme('spacingTop.xl')} ${selectTheme('spacingLeft.5xl')};
  text-decoration: none;

  &[class^='css-'] {
    width: fit-content;
  }

  svg {
    margin-left: ${selectTheme('spacingLeft.11xl')};

    ${media.up('5xl')} {
      margin-left: ${selectTheme('spacingLeft.14xl')};
    }
  }

  ${media.up('5xl')} {
    height: 60px;
    margin-bottom: 0;
    padding: ${selectTheme('spacingTop.4xl')} ${selectTheme('spacingTop.6xl')};
  }
`

export {
  Wrapper,
  Container,
  ContentTitle,
  ContentSubTitle,
  ContentFooter,
  Description,
  Actions,
  ExtraInfo,
  Image,
  ImageContainer,
  StyledButtonCta,
  StyledSupportCardWrapper,
  StyledReclameAquiAward,
  imageStyle,
}
