import { css, styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'
import { media, typography } from '@creditas-lab/style-utilities'
import { Tag as CreditasLabTag } from '@creditas-lab/tag'

import { ButtonCta } from 'components/ButtonCta'
import { WebCardBody } from 'components/Card'
import { LazyImage } from 'components/LazyImage'

const WRAPPER_VARIANT = {
  storeCustomCard: css`
    width: 236px;
    height: 100%;

    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.04),
      -16px 0px 16px -12px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;

    ${media.md`
      width: 288px;
    `}
  `,
  default: `
    box-shadow: 0 0 64px rgba(20, 20, 20, 0.04);
  `,
}

const Wrapper = styled.div(
  ({ variant }) => css`
    position: relative;
    border-radius: 20px;

    ${WRAPPER_VARIANT[variant] || WRAPPER_VARIANT.default}

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 24px;
      right: 24px;
      bottom: 0;
      border-radius: 24px;
      box-shadow: 0 28px 64px rgba(20, 20, 20, 0.08);
      z-index: 0;
    }
  `,
)

const Content = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 20px;
    border-radius: 20px;
    height: 100%;
    background-color: ${theme.colorNeutralWhite};
    z-index: 1;

    ${media.md`
      padding: 24px;
    `}
  `,
)

const Tag = styled(CreditasLabTag)``

const Title = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colorFontNeutral90};
    margin-bottom: 12px;
    ${typography.mobile.titleSM700(theme)}
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.3px;

    ${media.md`
      margin-bottom: 16px;
      ${typography.desktop.titleXS700(theme)}
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.5px;
    `}
  `,
)

const Body = styled(WebCardBody)(
  ({ theme }) => css`
    white-space: pre-line;
    color: ${theme.colorFontNeutral60};
    ${typography.mobile.paragraphMD400(theme)}
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;

    ${media.md`
      ${typography.desktop.paragraphMD400(theme)}
    `}

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    > p {
      margin-bottom: 8px;
    }

    > p:last-of-type {
      margin-bottom: 0;
    }

    b {
      display: block;
      color: 8px;
    }
  `,
)

const Cta = styled(ButtonCta)(
  ({ theme }) => css`
    margin-top: auto;
    border: 1px solid transparent;
    text-decoration: none;
    color: ${theme.colorBrandPrimaryDefault};
    ${typography.desktop.paragraphMD700(theme)}

    &:hover {
      color: ${theme.colorBrandPrimaryDefault};
      text-decoration: underline;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    svg {
      color: ${theme.colorBrandPrimaryDefault};
      display: block;
      margin-left: 8px;
    }
  `,
)

const Image = styled(LazyImage)`
  margin: 40px -24px 32px -24px;
`

export { Wrapper, Content, Tag, Title, Body, Cta, Image }
