import React from 'react'
import { bool, object, oneOf, oneOfType, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { ArrowRightIcon } from 'components/Icons'
import { SupportCard } from 'components/SupportCard'

import { PreTitle } from '../components/PreTitle'
import { Title } from '../components/Title'

import {
  Actions,
  Container,
  ContentFooter,
  ContentSubTitle,
  ContentTitle,
  Description,
  ExtraInfo,
  Image,
  ImageContainer,
  StyledButtonCta,
  StyledReclameAquiAward,
  StyledSupportCardWrapper,
  Wrapper,
} from './styles'

const propTypes = {
  buttonCta: object,
  description: string,
  descriptionText: string,
  headingType: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  heroImage: object,
  preTitle: string,
  preTitleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  richTextTitle: oneOfType([object, string]),
  shouldDisplayPreTitle: bool,
  showReclameAquiAward: bool,
  supportCard: object,
  title: string,
}

const defaultProps = {
  headingType: 'h1',
  preTitleHeadingTag: 'h2',
  shouldDisplayPreTitle: true,
  showReclameAquiAward: true,
}

const Template02 = props => {
  const {
    buttonCta,
    descriptionText,
    headingType,
    heroImage,
    preTitle,
    preTitleHeadingTag,
    shouldDisplayPreTitle,
    showReclameAquiAward,
    supportCard,
    title,
    ...others
  } = props

  const { triggerEvent } = useTriggerEvents()

  const handleClick = () => {
    if (!buttonCta?.label || !buttonCta?.actionType) return

    const isPageInteraction = buttonCta.actionType !== 'link'
    triggerEvent({ label: buttonCta.label, pageInteraction: isPageInteraction })
  }

  if (heroImage?.artDirection?.[1]?.aspectRatio) {
    heroImage.artDirection[1].aspectRatio = 1
  }

  const showReclameAqui =
    showReclameAquiAward === null
      ? defaultProps.showReclameAquiAward
      : showReclameAquiAward

  return (
    <Wrapper as="section" {...others}>
      <Container>
        <ContentTitle area="content">
          <PreTitle
            as={preTitleHeadingTag}
            variant={{
              '4xl': 'exceptionMdBold',
              _: 'exceptionSmBold',
            }}
            shouldDisplayPreTitle={shouldDisplayPreTitle}
          >
            {preTitle}
          </PreTitle>
          <Title
            as={headingType}
            color="neutral.90"
            variant={{
              '4xl': 'heading2xlLight',
              _: 'headingMdLight',
            }}
          >
            {title}
          </Title>
        </ContentTitle>
        <ContentSubTitle area="subtitle">
          <Description
            data-testid="description"
            color="neutral.80"
            variant={{
              '4xl': 'bodyXlLight',
              _: 'bodyMdRegular',
            }}
          >
            {descriptionText}
          </Description>
        </ContentSubTitle>
        <ContentFooter area="content-footer">
          {buttonCta && (
            <Actions>
              <StyledButtonCta
                {...buttonCta}
                onClick={handleClick}
                analyticEvent={false}
                size="large"
              >
                <ArrowRightIcon />
              </StyledButtonCta>
            </Actions>
          )}
          {showReclameAqui && (
            <ExtraInfo>
              <StyledReclameAquiAward data-testid="award" variant="black" />
            </ExtraInfo>
          )}
        </ContentFooter>
        <ImageContainer area="image">
          {heroImage?.artDirection && (
            <Image
              {...heroImage}
              imgStyle={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          )}
          {supportCard && (
            <StyledSupportCardWrapper>
              <SupportCard {...supportCard} data-testid="support-card" />
            </StyledSupportCardWrapper>
          )}
        </ImageContainer>
      </Container>
    </Wrapper>
  )
}

Template02.propTypes = propTypes
Template02.defaultProps = defaultProps

export default Template02
