import React from 'react'
import { arrayOf, bool, object, oneOf, shape, string } from 'prop-types'

import { GridItem } from '../base.styles'
import { Card } from '../components/Card'
import { Description } from '../components/Description'
import { PreTitle } from '../components/PreTitle'

import {
  CardsShelf,
  Grid,
  Header,
  Image,
  StyledReclameAquiAward,
  Title,
  Wrapper,
} from './styles'

const propTypes = {
  cards: arrayOf(
    shape({
      buttonCta: object,
      description: string,
      title: string,
    }),
  ),
  descriptionText: string,
  headingType: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  heroImage: object,
  preTitle: string,
  preTitleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  shouldDisplayPreTitle: bool,
  showReclameAquiAward: bool,
  title: string,
}

const defaultProps = {
  headingType: 'h1',
  preTitleHeadingTag: 'h2',
  shouldDisplayPreTitle: false,
  showReclameAquiAward: true,
}

const Template01 = props => {
  const {
    cards,
    descriptionText,
    headingType,
    heroImage,
    preTitle,
    preTitleHeadingTag,
    shouldDisplayPreTitle,
    showReclameAquiAward,
    title,
    ...others
  } = props

  const showReclameAqui =
    showReclameAquiAward === null
      ? defaultProps.showReclameAquiAward
      : showReclameAquiAward

  return (
    <Wrapper as="section" {...others}>
      <Grid>
        {(title || descriptionText) && (
          <Header>
            <PreTitle
              as={preTitleHeadingTag}
              variant="exceptionSmBold"
              color="neutral.60"
              shouldDisplayPreTitle={shouldDisplayPreTitle}
            >
              {preTitle}
            </PreTitle>

            <Title
              as={headingType}
              color="neutral.90"
              variant={{
                _: 'headingMdLight',
                '5xl': 'headingXlLight',
              }}
            >
              {title}
            </Title>

            <Description
              variant={{
                _: 'bodyMdLight',
                '5xl': 'bodyXlLight',
              }}
              color="neutral.60"
              data-testid="description"
            >
              {descriptionText}
            </Description>
          </Header>
        )}
        {heroImage?.artDirection && <Image {...heroImage} />}
        {cards?.length && (
          <GridItem area="children">
            <CardsShelf>
              {cards?.map((card, index) => (
                <Card key={`CustomCard-${index}`} index={index} {...card} />
              ))}
            </CardsShelf>
          </GridItem>
        )}
        {showReclameAqui && (
          <StyledReclameAquiAward data-testid="award" variant="black" />
        )}
      </Grid>
    </Wrapper>
  )
}

Template01.propTypes = propTypes
Template01.defaultProps = defaultProps

export default Template01
