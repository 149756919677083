import { css, styled } from '@creditas/stylitas'
import { media } from '@creditas-lab/style-utilities'
import { Typography } from '@creditas-lab/typography'

import { WebCard } from '../Card'

const Container = styled(WebCard)`
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08), 0 12px 12px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  position: relative;
  overflow: visible;
  padding: 24px;
`

const Content = styled.div(
  ({ hasIcon }) => css`
    display: flex;

    ${media.md`
      ${hasIcon && `margin-top: 8px;`}
      flex-direction: column;
    `}
  `,
)

const IconBox = styled.div(
  ({ theme }) => css`
    width: 48px;
    height: 48px;
    background-color: ${theme.colorBackgroundLight};
    box-shadow: 0 16px 8px -12px rgba(0, 0, 0, 0.12),
      0 16px 12px -8px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    top: -32px;
    right: 16px;

    ${media.md`
      width: 60px;
      height: 60px;
      right: 24px;
      top: -36px;
    `};
  `,
)

const Description = styled(Typography)(
  ({ theme }) => css`
    margin-left: 8px;
    color: ${theme.colorNeutral80};
    flex: 1;
    ${media.md`
      margin-left: 0px;
    `};
  `,
)

export { Container, Content, Description, IconBox }
