import React from 'react'
import { object, string } from 'prop-types'

import { AvatarImage } from '../AvatarImage'
import CustomIcon from '../CustomIcon'

import { Container, Content, Description, IconBox } from './styles'

const propTypes = {
  avatar: object,
  description: string,
  icon: object,
}

const SupportCard = ({ avatar, description, icon, ...others }) => {
  return (
    <Container {...others}>
      {icon && (
        <IconBox>
          <CustomIcon {...icon} />
        </IconBox>
      )}
      <Content hasIcon={!!icon}>
        {avatar && <AvatarImage image={avatar} />}
        {description && (
          <Description mobile="SM" desktop="MD" weight="SM">
            {description}
          </Description>
        )}
      </Content>
    </Container>
  )
}

SupportCard.propTypes = propTypes

export { SupportCard }
