import React from 'react'
import { number, object } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { CustomCard } from 'components/Card/CustomCard'

const propTypes = {
  buttonCta: object,
  index: number,
}

const Card = ({ index, ...card }) => {
  const { triggerEvent } = useTriggerEvents()

  const handleCardClick = (label, url) => {
    const isScroll = !!url?.startsWith('#')

    triggerEvent({
      label: `${index + 1} | ${label}${isScroll ? ' | scroll' : ''}`,
      pageInteraction: isScroll,
    })
  }

  const button = {
    analyticEvent: false,
    onClick: () => handleCardClick(card?.linkText, card?.linkUrl),
  }

  return <CustomCard {...card} buttonCta={button} />
}

Card.propTypes = propTypes

export { Card }
