import React from 'react'
import { object, oneOf, oneOfType, string } from 'prop-types'

import { ArrowRightIcon } from 'components/Icons'

import {
  Body,
  Content,
  Cta,
  Image,
  Tag,
  Title,
  Wrapper,
} from './customCard.style'

const propTypes = {
  buttonCta: object,
  'data-testid': string,
  description: oneOfType([object, string]),
  image: object,
  linkText: string,
  linkUrl: string,
  tag: string,
  title: string,
  variant: oneOf(['default', 'storeCustomCard']),
}

const defaultProps = {
  'data-testid': 'custom-card',
  variant: 'default',
}

const CustomCard = ({
  buttonCta,
  'data-testid': dataTestId,
  description,
  image,
  linkText,
  linkUrl,
  tag,
  title,
  variant,
  ...others
}) => {
  const isValidButtonCta =
    (buttonCta?.label && buttonCta?.actionHref) || (linkUrl && linkText)
  const buttonCtaProps = {
    variant: 'actionLink',
    icon: ArrowRightIcon,
    label: linkText,
    actionHref: linkUrl,
    ...buttonCta,
  }

  if (image?.childImageSharp) {
    image = image.childImageSharp
  }

  return (
    <Wrapper variant={variant} data-testid={dataTestId} {...others}>
      <Content>
        {tag && <Tag as="span">{tag}</Tag>}
        {image && <Image {...image} />}
        {title && <Title as="p">{title}</Title>}
        {description && <Body body={description} />}
        {isValidButtonCta && <Cta {...buttonCtaProps} />}
      </Content>
    </Wrapper>
  )
}

CustomCard.propTypes = propTypes
CustomCard.defaultProps = defaultProps

export { CustomCard }
