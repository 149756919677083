import React from 'react'
import { object, string } from 'prop-types'

import Template01 from './Template01'
import Template02 from './Template02'
import { setArtDirection } from './utils/setArtDirection'

const propTypes = {
  backgroundImageDesktop: object,
  backgroundImageMobile: object,
  variant: string,
}

const defaultProps = {
  variant: 'default',
}

const TEMPLATES = {
  default: Template01,
  withSupportCard: Template02,
}

const HeroImage = ({
  backgroundImageDesktop,
  backgroundImageMobile,
  variant,
  ...others
}) => {
  const Section = TEMPLATES[variant || defaultProps.variant]
  const images = setArtDirection(backgroundImageDesktop, backgroundImageMobile)

  return <Section heroImage={images} {...others} />
}

HeroImage.propTypes = propTypes
HeroImage.defaultProps = defaultProps

export { HeroImage }
export default HeroImage
