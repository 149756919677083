import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container } from 'components/Container'
import { Grid as UiGrid } from 'components/Grid'
import { LazyImage } from 'components/LazyImage'

import ReclameAquiAward from '../../../components/ReclameAquiAward'
import { Title as StyledTitle } from '../components/Title'

const Wrapper = styled(Container)`
  padding-top: ${selectTheme('spacingTop.8xl')};

  ${media.up('5xl')} {
    padding-top: ${selectTheme('spacingTop.5xl')};
  }
`

const Grid = styled(UiGrid)`
  grid-template-rows: auto;
  grid-template-areas:
    '${'header '.repeat(4)}'
    '${'children '.repeat(4)}'
    '${'image '.repeat(4)}'
    '${'stamps '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'header '.repeat(8)}'
      '${'children '.repeat(8)}'
      '${'image '.repeat(8)}'
      '${'stamps '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-areas:
      '. ${'header '.repeat(5)} . ${'image '.repeat(5)}'
      '. ${'stamps '.repeat(5)} . ${'image '.repeat(5)}'
      '${'children '.repeat(7)} ${'image '.repeat(5)}';
  }
`

const Header = styled.div`
  grid-area: header;
  margin-top: 0;
  margin-bottom: ${selectTheme('spacingBottom.14xl')};

  ${media.up('4xl')} {
    margin-top: 58px;
    margin-bottom: 32px;
  }

  ${media.up('7xl')} {
    margin-top: 82px;
  }
`

const Title = styled(StyledTitle)`
  margin-bottom: ${selectTheme('spacingBottom.6xl')};
`

const Image = styled(LazyImage)(
  ({ theme }) => css`
    grid-area: image;
    margin-top: -300px;
    margin-left: -${selectTheme('spacingLeft.5xl')({ theme })};
    margin-right: -${selectTheme('spacingRight.5xl')({ theme })};

    ${media.up('5xl')} {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }
  `,
)

const CardsShelf = styled.div(
  ({ children, theme }) => css`
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    width: auto;
    margin-left: -${selectTheme('spacingLeft.3xl')({ theme })};
    margin-right: -${selectTheme('spacingRight.3xl')({ theme })};
    margin-bottom: ${selectTheme('spacingBottom.16xl')({ theme })};

    ${media.up('sm')} {
      margin-left: -20px;
      margin-right: -20px;
    }

    ${media.up('5xl')} {
      overflow: ${children?.length > 4 ? 'auto' : 'visible'};
      width: 100vw;
    }

    &:after,
    &:before {
      content: '';
      display: block;
      min-width: 1px;
    }

    &:before {
      margin-right: ${selectTheme('spacingRight.md')({ theme })};
      margin-left: -1px;
    }

    &:after {
      margin-left: ${selectTheme('spacingLeft.md')({ theme })};
      margin-right: -1px;
    }

    & > * {
      width: 236px;
      min-width: 236px;
      margin: 0 ${selectTheme('spacingLeft.md')({ theme })};

      ${media.up('5xl')} {
        width: 288px;
        min-width: 288px;
      }
    }
  `,
)

const StyledReclameAquiAward = styled(ReclameAquiAward)`
  align-items: center;
  grid-area: stamps;
  margin-bottom: ${selectTheme('spacingBottom.10xl')};
  margin-top: 32px;
  width: 100%;

  p {
    color: ${selectTheme('colors.neutral.80')};
  }

  ${media.up('4xl')} {
    p {
      max-width: 360px;
    }
  }
`

export {
  CardsShelf,
  Grid,
  Header,
  Image,
  Title,
  Wrapper,
  StyledReclameAquiAward,
}
