import { css, styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { selectTheme } from '@creditas-ui/utilities'

export const PreTitle = styled(Typography)(({ shouldDisplayPreTitle, theme }) =>
  shouldDisplayPreTitle
    ? css`
        color: ${selectTheme('colors.neutral.60')({ theme })};
        margin-bottom: ${selectTheme('spacingBottom.3xl')({ theme })};
        text-transform: uppercase;
      `
    : css`
        display: none;
      `,
)
