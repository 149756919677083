import React from 'react'
import { object } from 'prop-types'

import { Container, StyledImage } from './styles'

const propTypes = {
  image: object,
}

const AvatarImage = ({ image, ...others }) => {
  return (
    <Container {...others}>
      <StyledImage {...image} />
    </Container>
  )
}

AvatarImage.propTypes = propTypes

export { AvatarImage }
