import React from 'react'
import { string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: string,
  color: string,
}

const defaultProps = {
  color: 'neutral.90',
}

export const Title = ({ children, ...others }) => {
  if (!children) return null

  return (
    <Typography {...others} dangerouslySetInnerHTML={{ __html: children }} />
  )
}

Title.propTypes = propTypes
Title.defaultProps = defaultProps
