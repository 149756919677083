import React from 'react'
import { string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  children: string,
  color: string,
}

const defaultProps = {
  color: 'neutral.80',
}

export const Description = ({ children, ...others }) => {
  if (!children) return null

  return (
    <Typography {...others} dangerouslySetInnerHTML={{ __html: children }} />
  )
}

Description.propTypes = propTypes
Description.defaultProps = defaultProps
