import React from 'react'
import { bool, string } from 'prop-types'

import { PreTitle as StyledPreTitle } from './styles'

const propTypes = {
  children: string,
  color: string,
  shouldDisplayPreTitle: bool,
}

const defaultProps = {
  color: 'neutral.90',
  shouldDisplayPreTitle: false,
}

export const PreTitle = ({ children, shouldDisplayPreTitle, ...others }) => {
  if (!children) return null

  return (
    <StyledPreTitle
      dangerouslySetInnerHTML={{ __html: children }}
      shouldDisplayPreTitle={
        shouldDisplayPreTitle
          ? shouldDisplayPreTitle
          : defaultProps.shouldDisplayPreTitle
      }
      {...others}
    />
  )
}

PreTitle.propTypes = propTypes
PreTitle.defaultProps = defaultProps
