import { styled } from '@creditas/stylitas'

import { LazyImage } from '../LazyImage'

const Container = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
`

const StyledImage = styled(LazyImage)`
  width: 100%;
  height: 100%;
`

export { Container, StyledImage }
